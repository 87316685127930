.os-form-builder-container {
  /* The MUI Flexbox i.e. Stack */
  width: 80%;
  margin-left: 100px !important;
  padding-left: 20px;
}

.os-form-builder-container > span.title,
.os-form-builder-container > h5.subtitle {
  /*The titles*/
}

.os-form-builder-container > span.title {
  padding-top: 20px;
}

.os-form-builder-container > span.title input[type="text"] {
  /* The form title in edit mode */
  margin: 0;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
  font-size: 1.8214285714285714rem;
  line-height: 1.235;
}

.os-form-builder-container .content {
  /* The content area shared amongst all form builder components*/
}

.os-form-builder-container .content .fields-container {
  position: relative; /* So that the draggable items (field configs) appear properly. We are using css animations to adjust position of items while dragging so the items css use position: absolute. Thus, this parent has to be relative i.e. cannot be static.*/
}

/*------------ Field Dependencies ------------*/
.os-form-builder-container .field-dependencies {
}

.os-form-builder-container .field-dependencies button {
}

.os-form-builder-container .field-dependencies > div.field-dependency {
  background: rgb(255 255 255 / 80%);
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 9%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

div.field-dependency > .buttons {
  margin-left: auto;
}

.os-form-builder-container .field-dependencies > .dependency-actions {
}

.os-form-builder-container .field-dependencies > .dependency-conditions {
}

.dependency-edit-card {
}

.dependency-edit-card .condition-logic-type {
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: rgba(var(--primary), 10%);
  color: rgba(var(--primary), 100%);
  font-weight: bold;
}

.dependency-edit-card .condition-logic-type .MuiFormControl-root {
  margin-left: 20px;
}

.dependency-edit-card .condition-logic-type .MuiSelect-root {
  max-height: 24px;
  font-weight: bold;
  background-color: white;
}

.dependency-edit-card .MuiOutlinedInput-root {
  background-color: white;
}

.dependency-edit-card .dependency-conditions {
  padding: 30px;
  background-color: rgba(0, 0, 255, 5%);
}

.dependency-edit-card .dependency-actions {
  padding: 30px;
  background-color: rgba(0, 0, 255, 5%);
}

.dependency-edit-card .dependency-conditions .title,
.dependency-edit-card .dependency-actions .title {
  margin-bottom: 30px;
}

.dependency-edit-card .save-button,
.dependency-edit-card .dependency-conditions .add-button,
.dependency-edit-card .dependency-actions .add-button {
  margin-left: auto;
}

.dependency-edit-card .dependency-actions .permitted-values-button {
  padding-top: 10px;
}

.dependency-edit-card .dependency-conditions .single-condition,
.dependency-edit-card .dependency-conditions .condition-group {
  background-color: rgba(var(--primary), 6%);
  border-radius: 12px;
  padding: 20px;
}

.dependency-edit-card .dependency-conditions .condition-group .condition-logic-type {
  border-radius: 6px;
}

/*------------------------------------*/

/*------------ Field Validators ------------*/
.os-field-validator-form-container .os-many2one-add-new-container {
  margin-right: 20px;
}
/*------------------------------------*/

.os-form-builder-field-preview {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 12px !important;
  min-height: 80px;
}

.os-form-builder-field-preview.selected {
  cursor: move !important;
  border: solid 3px !important;
  border-color: rgba(var(--primary)) !important;
  padding: 20px;
}

.os-form-builder-field-preview.selected.drag-preview {
  width: 720px;
  background: white;
}

/* Targeting a MUI input field using css selectors does not seem to work as with vanilla DOM 
elements so we target them using a class 
https://stackoverflow.com/questions/51368034/pointer-events-none-does-not-work-on-material-ui-textfield-input
*/
.os-form-builder-field-preview-MUIInput {
  pointer-events: none !important;
}

.os-form-builder-field-preview-action {
  cursor: pointer;
}

div.os-date-limitation-value-setting {
  /* Overriding the os form default 8px margin set in OSForm Component sx prop i.e. {m: 1}*/
  margin: 0 !important;
}

.os-date-limitation-value-setting .MuiInput-input {
  /* Overriding default css for MUI inputs for the data limitation settings elements */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box !important;
}

/* Field Type Icons */

.os-single-line-text-field-icon {
}

.os-textarea-field-icon {
}

.os-email-field-icon {
}

.os-checkbox-field-icon {
}

.os-multi-checkbox-field-icon {
}

.os-dropdown-field-icon {
}

.os-multi-dropdown-field-icon {
}

.os-radio-field-icon {
}

.os-file-field-icon {
}

.os-multi-file-field-icon {
}

.os-date-field-icon {
}

.os-datetime-field-icon {
}

.os-dob-field-icon {
}

.os-hidden-field-icon {
}

.os-number-field-icon {
}

.os-url-field-icon {
}

.os-section-break-field-icon {
}

.os-gis-field-icon {
}

.os-page-break-field-icon {
}

.os-button-check-field-icon {
}

.os-matrix-field-icon {
}

.os-matrix-view-field-icon {
}

.os-dynamic-matrix-field-icon {
}

.os-form-entries-field-icon {
}

.os-multi-form-entries-field-icon {
}

.os-title-field-icon {
}

.os-auto-complete-field-icon {
}

.os-source-complete-field-icon {
}

.os-signature-field-icon {
}

.os-photo-field-icon {
}

.os-phone-field-icon {
}
