.os-many2one-add-new-container {
  position: relative; /* set relative so we can center children using absolute position*/
}

.os-many2one-add-button {
  position: absolute;
  top: 15%;
  transform: translate(-40%);
}

.os-form-input-help-text p {
  /* Borrowed from Eugene's great styling of os-preview-prebuilt-service-main*/
  font-size: 1.1em;
  font-weight: 300;
  color: #2d2d2d;
}

.tabulated-one2many-head-row {
}

.tabulated-one2many-head-cell {
  padding: 6px 16px;
  text-transform: uppercase;
}

.tabulated-one2many-body-row {
  cursor: pointer;
}

.tabulated-one2many-body-row.selected {
  background: rgba(var(--primary), 5%);
}

.tabulated-one2many-body-row:hover {
  background: rgba(var(--primary), 10%);
}

.tabulated-one2many-body-cell {
  color: var(--grey);
  padding: 6px 16px;
  border-bottom: 1px solid var(--lightGrey);
  display: flex; /* Center vertically similar to a table cell */
  align-items: center; /* Center vertically similar to a table cell */
}
