/* Protected Base Container */
.os-protected-base-container {
  display: flex;
  height: 100vh;
}

.os-protected-base-container > main {
  flex-grow: 1;
  padding: 24px;
  min-width: 81.5vw; /* Fixing flexbox gotcha - https://gaurav5430.medium.com/css-flex-positioning-gotchas-child-expands-to-more-than-the-width-allowed-by-the-parent-799c37428dd6 */
}

/* End Protected Base Container */

/* APP SIDEBAR */
.app-sidebar > div,
.app-sidebar > div > div {
  width: 300px !important;
  overflow: visible;
}

.app-sidebar > div > div {
  background: rgba(var(--primary), 2%) !important;
  overflow-x: visible !important;
  border-right: 0px !important;
}

.app-sidebar.close .sidebar-options-A {
  /* visibility: hidden; */
  display: none;
}

.app-sidebar.close {
}

.app-sidebar.close + main {
}

.app-sidebar.close > div,
.app-sidebar.close > div > div,
.app-sidebar.close {
  width: 120px !important;
  overflow: hidden;
}

.app-sidebar.close .second-side-bar {
  margin-left: 40px !important;
}

.app-sidebar.close .app-primary-nav-text {
  display: none;
}

.app-sidebar.close a.brand-wrapper-link {
  visibility: hidden;
  width: 0px;
}

button.toggle-sidebar-button.close {
  right: -5px;
}

.second-side-bar-in {
  background: transparent !important;
}

.app-sidebar.close .app-primary-nav a {
  width: 40px;
}

/* END APP SIDEBAR */

ul.user-popup li {
  font-size: 1em;
  color: var(--grey-02);
  padding: 8px 15px;
}

.user-popup-icon svg {
  width: 18px;
}

li.user-popup-logout {
  border-top: 1px solid #f7f7f7;
}

ul.user-popup {
  margin: 0px;
  padding: 0px;
}

ul.user-popup li {
  font-size: 1em;
  color: var(--grey-02);
  padding: 8px 15px;
}

a.bm-logout {
  display: none;
}

.app-primary-nav a .MuiListItemIcon-root {
  min-width: 36px;
}

.sidebar-options-A {
  padding-left: 13px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 180px;
}

.sidebar-options-A > div.sidebar-org-switch {
  width: 100%;
}

.sidebar-options-A > div.sidebar-org-switch > div {
  width: 100% !important;
}

.app-primary-nav a {
  padding: 8px 8px;
  border-radius: 6px;
  color: var(--grey-02);
}

.app-primary-nav a .MuiListItemIcon-root {
  color: var(--grey-02);
}

.MuiDrawer-root .MuiMenuItem-root.Mui-selected svg {
  color: rgba(var(--primary));
}

.MuiDrawer-root .MuiMenuItem-root.Mui-selected {
  background: rgb(255 255 255 / 0%);
  color: rgba(var(--primary));
}

.first-side-bar {
  width: 60px;
  height: 100%;
  position: fixed;
  background: var(--default-gradient-background);
  background: var(--default-gradient);
}

.second-side-bar {
  margin-left: 60px !important;
}

.first-sidebar-in {
  position: relative;
  height: 100%;
}

.first-sidebar-in > div {
  background: transparent;
}

a.user-icon {
  width: 40px;
  height: 40px;
  background: rgb(0 0 0 / 10%);
  display: block;
  margin-left: 9px;
  border-radius: 50%;
  cursor: pointer;
}

a.user-icon:hover {
  background: rgb(0 0 0 / 30%);
}

.first-sidebar-bottom-items {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  max-width: 60px;
}

.first-sidebar-bottom-items > button {
  color: #fff;
  margin-left: 9px;
  margin-bottom: 1em;
}

@media (max-width: 980px) {
  .os-home-cards > div,
  .os-home-cards > div > div {
    display: block !important;
    width: 100% !important;
    max-width: unset;
    margin: 0px 0px 10px 0px;
    padding: 0px !important;
  }

  .app-sidebar.close > div,
  .app-sidebar.close > div > div,
  .app-sidebar.close {
    width: 100% !important;
  }
}
