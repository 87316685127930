@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.os-service-builder-full-screen,
.os-service-builder-full-screen a,
.os-service-builder-full-screen p,
.os-service-builder-full-screen span {
  font-family: "Source Sans Pro";
}

/* SERVICE BUILDER CONTAINER */
.os-service-builder-container {
  margin-top: 0px !important;
  width: 100%;
  margin-left: 280px !important;
  background: #f0f0f0;
  height: 100%;
  overflow-y: hidden;
}

.os-service-builder-full-screen .MuiDialog-container > .MuiPaper-root > div {
  height: 100%;
}

.os-service-builder-container .MuiTabs-root a {
  font-weight: 500;
  font-size: 1em;
  padding: 30px 20px;
}

/* SERVICE BUILDER LEFT PANEL */
.os-service-builder-left-panel {
  display: flex;
  position: fixed;
  /* padding-top: 80px; */
  height: 100%;
  min-width: 200px;
  max-width: 295px;
  border-radius: 0px !important;
  overflow-y: auto;
  /* padding-left: 15px; */
  /* display: flex; */
  overflow-x: hidden;
  border-right: 1px solid #7b00b10d;
  z-index: 2;
}

.os-sb-left-nav-topitem.os-sb-left-nav-title:hover,
.os-sb-left-nav-topitem.os-sb-left-nav-title:hover a {
  background: transparent;
  cursor: auto;
}

.os-service-builder-left-panel .first-side-bar {
  /* position: relative;
  width: 60px;
  float: left; */
  position: fixed;
  height: 100%;
}

.os-service-builder-left-panel .first-side-bar-in {
  height: 100%;
  position: absolute;
}

.os-sb-left-nav-icon-action {
  float: none;
  width: 30px;
  text-align: center;
}

.os-service-builder-left-panel .first-side-bar .image-style-A {
  max-width: 12px;
  width: 100%;
  position: absolute;
}

.os-service-builder-left-panel .os-sb-left-nav {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 60px;
  width: 100%;
  max-width: 220px;
  background: #fff;
}

/* SERVICE BUILDER LEFT PANEL HEADER */
.os-service-builder-sidebar-header {
  padding: 20px 0px 20px 0px !important;
  border-radius: 5px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.os-service-builder-sidebar-title span {
  font-weight: 700;
  color: #7b00b1;
  line-height: 1.2em;
}

/* SERVICE BUILDER LEFT PANEL NAV ITEMS */
.os-sb-left-nav-topitem .MuiListItemButton-root {
  padding: 5px 5px !important;
  height: unset;
}

.os-sb-left-nav-topitem .MuiListItemText-root {
  /* width: 100%;
  display: block;
  max-width: 160px; */
  margin: 0px;
}

.os-sb-left-nav-topitem > div > div {
  display: flex;
}

/* SERVICE BUILDER TOOLBAR */
.os-services-toolbar-wrapper {
  border: 1px solid #eaeaea;
  margin-top: 3em;
  margin-bottom: 1em;
  border-radius: 8px;
}

.os-sb-left-nav-topitem {
  margin-right: 17px;
}

.os-services-toolbar-wrapper,
.os-sb-left-nav-topitem {
  max-width: 173px;
}

.os-services-toolbar-title span {
  font-weight: 500;
  text-align: center;
}

/* SERVICE BUILDER TOOLBAR ITEMS */
.os-services-toolbar-buttons > div:nth-last-child(1) > div {
  border-radius: 0px 0px 8px 8px !important;
}


/* SERVICE BUILDER WORKSPACE */
.os-service-builder-container-workspace {
  padding: 24px 5% 24px 5%;
  overflow-y: auto;
  width: 100%;
  margin: 0em auto !important;
  min-height: 85%;
}
/* SERVICE BUILDER WORKSPACE */

.os-service-builder-navbar a {
  color: #8a8a8a;
}

.os-service-builder-navbar {
  background: #fff;
  position: relative;
  width: 100%;
  color: #fff;
  /* height: 10vh; */
  border-bottom: 0px !important;
  box-shadow: 30px 0px 20px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.os-service-builder-navbar .MuiTabs-flexContainer {
  justify-content: center;
}

.os-service-builder-card {
  box-shadow: 0px 0px 20px 0px #eee;
  background: #fff;
  padding: 2em;
  border-radius: 5px;
  min-height: 60vh;
}

.os-service-builder-card nav.MuiList-root > div {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  margin-bottom: 5px;
}

.os-service-builder-card nav.MuiList-root > div:hover {
  border: 1px solid rgba(var(--primary));
}
