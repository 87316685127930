/* TextEditorWithLineNumbers.css */
.editor-container {
    display: flex;
    position: relative;
    min-width: 100%;
  }
  
  .line-numbers {
    padding: 0 10px 0 10px;
    background: #f0f0f0;
    border-right: 1px solid #ddd;
}
  
  .text-area {
    flex: 1;
    border: none;
    outline: none;
    resize: none;
  }
  