.os-main-page-container.os-main-user-profile-container {
  /* max-width: 1000px; */
  background: rgba(var(--primary), 5%) !important;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.os-profile-header-container {
  min-height: 300px;
  width: 100%;
  position: relative;
  /* border-radius: 20px 20px 0px 0px; */
  background: var(--default-gradient);
}

.os-profile-header-content {
  position: relative;
  text-align: center;
  padding-top: 4em;
}

.os-profile-header-title {
  margin-top: 10px !important;
  font-size: 2em !important;
  font-weight: 300 !important;
}

.os-singleuser-information .MuiTabs-flexContainer {
  justify-content: center;
  padding: 5px 0px;
}

.os-singleuser-information > .MuiBox-root {
  border-bottom: 2px solid var(--grey-01);
}

.os-profile-information-container {
  max-width: 520px;
  margin: 0 auto;
  font-size: 1.1em;
  min-height: 250px;
}

.os-profile-field-label {
  color: var(--grey-04);
}

.os-profile-field-value {
  color: var(--grey);
}

.os-user-status-indicator button {
  border-radius: 5px;
  box-shadow: none;
  background: transparent;
  border: 1px solid;
  background: transparent !important;
}

.os-user-status-indicator.active button {
  color: #5ee85e !important;
}

.os-user-status-indicator.inactive button {
  color: #e85e5e !important;
}
