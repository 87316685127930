.os-task-template-list-view-container {
  position: relative;
  /* So that the draggable items (task templates) appear properly. We are using css animations to adjust position of task template items while dragging so the items css use position: absolute. Thus, this parent has to be relative i.e. cannot be static.*/
}

.os-workflow-flowchart-view-container {
  width: 100% !important;
  height: 1000px !important;
  margin-top: 24px !important;
}

.os-task-template-list-editor-top-bar div:last-child {
  margin-left: auto;
}

.os-task-template-editor-form-panel {
}

.os-task-template-drag-preview {
  width: 900px;
  cursor: move;
  /* transform: rotate(1deg);
    -webkit-transform: rotate(1deg); */
  /* color: white; */
  /*-webkit-animation: os-custom-drag-animation 1s infinite;*/
  /* Safari 4+ */
  /*-moz-animation: os-custom-drag-animation 1s infinite;*/
  /* Fx 5+ */
  /*-o-animation: os-custom-drag-animation 1s infinite;*/
  /* Opera 12+ */
  /*animation: os-custom-drag-animation 1s infinite;*/
  /* IE 10+, Fx 29+ */
}

.os-task-template-list-item-container > div {
  background: #fff;
  border-radius: 10px;
}

.os-task-template-list-item-container > div:hover {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.os-task-template-list-item-container {
  padding: 4px 0px;
}

.os-task-template-list-item-content > span {
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 4px;
}

.os-task-template-list-item-container > div > div:hover {
  /* background: rgba(0,0,0,0.02); */
}

.os-task-template-list-item-container > div:hover > div {
  cursor: pointer;
}

.os-task-template-list-item-content {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

@keyframes os-custom-drag-animation {
  0%,
  49% {
    background: var(--primaryVariant);
  }

  50%,
  100% {
    background: var(--secondaryVariant);
  }
}

.os-task-team-access-modal {
}

/* RIGHT SIDEBAR MODAL */
.os-inline-form .MuiTabs-flexContainer {
  max-width: 100%;
  width: 100%;
  margin-left: 0px !important;
  padding-right: 20px;
}

.os-bpmn-task-type-selector-icon-button {
  border: 1px solid #f3f3f3;
  margin: 5px;
  border-radius: 5px;
  padding: 10px;
}

.os-bpmn-task-type-selector-icon-button:hover {
  border: 1px solid rgba(var(--primary));
}

/* Task Type Icons */

.os-user-task-icon {
}

.os-start-event-icon {
}

.os-automated-task-icon {
}

.os-scheduled-task-icon {
}

.os-merge-node-icon {
}

.os-split-node-icon {
}

.os-end-task-icon {
}

.os-signal-node-icon {
}

.os-switch-node-icon {
}

.os-if-gateway-icon {
}

/* Gateway Nodes */
.os-workflow-chart-view-gateway-node {
  border-radius: 10px;
}

/* End Nodes */
.os-workflow-chart-view-end-node {
  border-radius: 10px;
}

/* Workflow Stages */
.workflow-stage-list-item.inactive {
  opacity: 0.5;
}

/* Start Customizing ReactFlow */
.react-flow__attribution {
  display: none; /* Hiding the attribution. Not sure if this is legal. Need to find out the legal implications. Nevertheless, we will subscribe for a pro subscription when funding starts coming in. https://github.com/wbkd/react-flow#commercial-usage--attribution */
}

.react-flow__controls {
  top: 20px !important;
  bottom: unset !important;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
}

.react-flow__controls-button {
}

/* Custom Edge Button - https://reactflow.dev/docs/examples/edges/edge-with-button */
.edgebutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid;
  cursor: pointer;
  border-radius: 50%;
  font-size: 8px;
  line-height: 1;
}

.edgebutton:hover {
  width: 35px;
  height: 35px;
}

.edgebutton-foreignobject body {
  background: transparent;
  color: #fff;
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

/* End Customizing ReactFlow */
