.os-drop-zone {
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: center; /* Align horizontally */
  border: 1px dashed #959595;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  font-size: 1.4em;
  position: relative;
  font-weight: 400;
}

.os-drop-zone-children {
  /* Commenting below out in favour of flexbox with centering */
  /* position: absolute;
  top: 50%; */
  /* Shift down */
  /* left: 50%; */
  /* Shift right */
  /* transform: translate(-50%, -50%); */
  /* Transform to center since before this, the start of the child is 50% down and 50% right */
}
